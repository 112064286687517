import styled, {css} from "styled-components";
import {TitleContainer, Title} from "../../containers/Login/styles";
const MediumFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Regular';
import {
  ButtonContainer,
  NextButton,
  FormSection,
  StyledInput,
  StyledDateTime,
  DownArrow,
} from "../RegistrationForm/styles";

const ImageContainer = styled(TitleContainer)`
  > img {
    min-width: 200px;
    height: 55px;
    cursor: pointer;
  }
`;
const FormTitle = styled(Title)`
  color: rgb(43, 81, 102);
`;
const ContentWrapper = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 40px;
  > div:first-child {
    padding: 15px 30px;
    @media (max-width: 767px) {
      width: 80%;
      float: left;
      margin: 0 10%;
      padding: 20px 20px 0px 20px;
    }
    @media (max-width: 600px) {
      padding: 20px 0px 0px 0px;
    }
    & > span {
     font-size: 20px;
     margin-left: 5px
     font-family: ${RegularFont}
    }  
    
    & >  span:first-child {
    display: inline-block;
    width: 30px;
    height: 30px;
    color: #fff;
    font-size: 20px;
    margin: 0 auto;
    text-align: center;
    background: rgb(43,81,102);
    }
    & > span:nth-child(3) {
      font-family: ${MediumFont}
    }
    & > div:last-child {
      display: flex;
      float: right;
      margin-right: 10px;
      @media (max-width: 800px) {
        float: none;
        margin-top: 20px;
        margin-left: -30px;
      }
    }
  }
`;
const ButtonWrapper = styled(ButtonContainer)`
  padding: 0 30px;
  @media (max-width: 767px)  {
    width: 80%;
    float: left;
    margin: 0 10%;
    padding: 0px 30px 10px 30px
  }
  @media (max-width: 600px) {
    padding: 0px 0px 10px   0px
  }
`;
const NavigationBtn = styled(NextButton)`
  margin-top: 15px;
  margin-bottom: 20px;
  float: ${({float}) => float ? 'right' : 'left'};
  background-color: ${({changeColor}) => changeColor ? '#ef6827' : 'rgb(43, 81, 102)'};
  border: 1px solid rgb(43,81,102);
   > i {
    margin-left: 10px;
    font-size: 12px;
  }
  ${({ disabled }) => disabled === true && css`
    opacity: 0.5;
    cursor: not-allowed !important;
  `}
`;
const FormWrapper = styled(FormSection)`
  width: 50%
  padding: ${({largePadding }) => largePadding ? largePadding : '0px 20px'}
  @media (max-width: 767px) {
    width: 80%;
    float: left;
    margin: 0 10%;
    padding: 20px 20px 10px 20px;
  }
  @media (max-width: 600px) {
    padding: 20px 0px 10px 0px
  }
`;

const SectionContainer = styled.div`
  width: 100%;
  display: inline-block;
  padding: 10px 0;
  
  @media (max-width: 500px) {
    width: 100%;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledLabel = styled.label`
  float: left;
  width: 100%;
  display: block;
  font-size: 14px;
  color: rgb(43,81,102);
  font-family: ${MediumFont};
  font-weight: 500;
  font-weight: normal;
  position: relative;
  pointer-events: none;
`;

const StyledBiometricInput = styled(StyledInput)`
 width: ${({fullWidth}) => fullWidth ? '100%' : '49%'}
 margin-right: ${({marginRight}) => marginRight ? '2%' : '0px'}
 margin-top: 0px;
 cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
`;

const StyledBiometricDatetime = styled(StyledDateTime)`

`;
const DownCaret = styled(DownArrow)`
  top: 56px;
  border-top: 7px dashed rgb(43,81,102);
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export {ImageContainer, FormTitle, ContentWrapper, ButtonWrapper, NavigationBtn, FormWrapper, SectionContainer, StyledLabel, StyledBiometricInput, StyledBiometricDatetime, DownCaret, RadioButtonWrapper}