/* eslint-disable no-console,no-undef, camelcase, no-useless-escape */
import React, { Component } from 'react';
import { UserBiometric, PersonalDetails } from '../../components';
import { withRouter } from "react-router-dom";
import {fetchSearchPeople, getUserBiometricDetails, saveBiometricInfo} from "../../redux/actions/peopleHomeActions";
import {saveUserInformation} from "../../redux/actions/registerActions";
import { connect } from 'react-redux';
import {ImageContainer, FormTitle} from './styles';
import PropTypes from 'prop-types';
import _ from 'lodash';

class MultiStepForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      openDatePicker: false,
      birthday: '',
      fname: '',
      lname: '',
      address1: '',
      city: '',
      state: '',
      mail: '',
      companyName: '',
      phoneNumber: '',
      postalCode: '',
      gender: '',
      bloodPressureDia: '',
      bloodPressureSys: '',
      bmi: '',
      cholestrolHdl: '',
      cholestrolLdl: '',
      cholestrolTotal: '',
      fasting: '',
      glucose: '',
      heightInFt: '',
      heightInInch: '',
      triglycerides: '',
      waistCircumference: '',
      weight: '',
    }
  }

  componentDidMount() {
    const {history, getUserBiometricDetails} = this.props;
    let UserId = history.location.pathname.split('/')[3];
    getUserBiometricDetails(UserId);
  }

  getFormattedBirthday = (date) => {
    const formattedDate = [];
    const birthday = new Date(Date.UTC(date.split('-')[0], date.split('-')[1], date.split('-')[2]));
    const month = this.getFormattedMonth(birthday.getUTCMonth());
    formattedDate.push(month, birthday.getUTCDate(), date.split('-')[0]);
    return formattedDate
  }; 

  getFormattedMonth = (monthValue) => {
    switch (monthValue) {
    case 0:
      return `${12}`;
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
      return `0${monthValue}`;
    case 10:
    case 11:
      return `${monthValue}`;
    default:
      return <div> </div>
    }
  };

  toggleRadioButton = (e) => {
    this.setState({[e.target.name]: e.target.value}, ()=>{
    });
  };

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   const {biometricDetails} = nextProps;
  //   if(biometricDetails && this.props.biometricDetails !== biometricDetails) {
  //     Object.keys(biometricDetails['user_details']).map((fieldName)=>{
  //       if(fieldName == 'birthday') {
  //         let formattedBirthdate = this.getFormattedBirthday(biometricDetails['user_details'][fieldName]);
  //         this.setState({ [fieldName] : formattedBirthdate.join('-')}, ()=>{
  //         })
  //       }
  //       else {
  //         this.setState({ [fieldName] : biometricDetails['user_details'][fieldName]}, ()=>{
  //         })
  //       }
  //     });
  //     Object.keys(biometricDetails['biometric_details']).map((fieldName)=>{
  //       if(fieldName == 'height') {
  //         let height = biometricDetails['biometric_details']['height'];
  //         if(!_.isNull(height)){
  //           this.setState({heightInFt: height.split('.')[0], heightInInch: height.split('.')[1]})
  //         }
  //       }
  //       else {
  //         this.setState({ [fieldName] :  _.isNull(biometricDetails['biometric_details'][fieldName]) ? '' : biometricDetails['biometric_details'][fieldName]}, ()=>{
  //         })
  //       }
  //     });
  //   }
  // }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { biometricDetails } = nextProps;
  
    if (biometricDetails && this.props.biometricDetails !== biometricDetails) {
      if (biometricDetails.user_details) {
        Object.keys(biometricDetails.user_details).map((fieldName) => {
          if (fieldName === 'birthday') {
            let formattedBirthdate = this.getFormattedBirthday(biometricDetails.user_details[fieldName]);
            this.setState({ [fieldName]: formattedBirthdate.join('-') });
          } else {
            this.setState({ [fieldName]: biometricDetails.user_details[fieldName] });
          }
        });
      }
  
      if (biometricDetails.biometric_details) {
        Object.keys(biometricDetails.biometric_details).map((fieldName) => {
          if (fieldName === 'height') {
            let height = biometricDetails.biometric_details.height;
            if (!_.isNull(height) && height.indexOf('.') !== -1) {
              this.setState({
                heightInFt: height.split('.')[0],
                heightInInch: height.split('.')[1],
              });
            }
          } else {
            this.setState({
              [fieldName]: _.isNull(biometricDetails.biometric_details[fieldName])
                ? ''
                : biometricDetails.biometric_details[fieldName],
            });
          }
        });
      }
    }
  }


  getMethod = (e) => {
    if(e.target.name == 'heightInFt') {
      this.setState({heightInFt:  e.target.value})
    }
    else if(e.target.name == 'heightInInch') {
      if(e.target.value >= 12) {
        this.setState({heightInInch:  e.target.value % 12, heightInFt: parseInt(this.state.heightInFt,10) +  Math.floor(e.target.value / 12) })
      }
      else {
        this.setState({heightInInch:  e.target.value})
      }
    }
    else {
      if(e.target.name === "postalCode" || e.target.name === "state" || e.target.name === "city" || e.target.name === "address1"){
        this.setState({[e.target.name]: e.target.value.replace(/[&\\#,+()$~%@^!_.'":*?<>{}]/g, "")}, () => {
        })
      }else {
        let onlyNumbers = /^[+-]?\d*(?:[.,]\d*)?$/;
        let number = e.target.value.match(onlyNumbers) ? e.target.value : this.state[e.target.name];
        this.setState({[e.target.name]: number}, () => {
        })
      }
    }
  };

  getFormComponent = () => {
    const {step, lname,  fname, address1, city, state, mail, companyName, phoneNumber, postalCode,  bloodPressureDia, bloodPressureSys,bmi, cholestrolHdl, cholestrolLdl, cholestrolTotal, fasting, glucose, height, triglycerides, waistCircumference, weight, heightInFt, heightInInch, birthday, gender} = this.state;
    const {history} = this.props;
    const values = {lname, fname, address1, city, state, mail, companyName, phoneNumber, postalCode, bloodPressureDia, bloodPressureSys,bmi, cholestrolHdl, cholestrolLdl, cholestrolTotal, fasting, glucose, height, triglycerides, waistCircumference, weight, heightInFt, heightInInch, birthday, gender};
    switch (step) {
    case 1:
      return <UserBiometric history={history} values = {values} handleChange={(e) =>this.getMethod(e)} goNext={this.goNext} toggleRadioButton={(e) => {this.toggleRadioButton(e)}}/>;
    case 2:
      return <PersonalDetails history={history} values = {values}   goBack={this.goBack} handleChange={(e) =>this.getMethod(e)} submitForm={this.submitForm} toggleRadioButton={(e) => {this.toggleRadioButton(e)}}/>
    default:
      return <div>{'hii'}</div>
    }
  };

  goNext = () => {
    const {step} = this.state;
    this.setState({step: step + 1});
  };

  goBack = () => {
    const {step} = this.state;
    this.setState({step: step - 1});
  };

  submitForm = () => {
    const {saveBiometricInfo, history, profile} = this.props;
    let UserId = history.location.pathname.split('/')[3];
    let obj = {};
    obj.uid = UserId;
    obj.fname = this.state.fname;
    obj.lname = this.state.lname;
    obj.phone_number =  this.state.phoneNumber;
    obj.birthday = this.state.birthday;
    obj.gender = this.state.gender;
    obj.address = this.state.address1;
    obj.state = this.state.state;
    obj.city = this.state.city;
    obj.postal_code = this.state.postalCode;
    obj.mail = this.state.mail;
    obj.height = this.state.heightInFt +'.'+ (this.state.heightInInch % 12);
    obj.weight =  this.state.weight;
    obj.waist_circumference =  this.state.waistCircumference;
    obj.bmi =  this.state.bmi;
    obj.blood_pressure_sys =  this.state.bloodPressureSys;
    obj.blood_pressure_dia =  this.state.bloodPressureDia;
    obj.fasting =  this.state.fasting;
    obj.cholestrol_total = (!_.isNull(this.state.cholestrolHdl) && _.isNull(this.state.cholestrolLdl)) ? (parseInt(this.state.cholestrolHdl,10) + parseInt(this.state.cholestrolLdl,10)) : 0;
    obj.cholestrol_hdl =  this.state.cholestrolHdl;
    obj.cholestrol_ldl =  this.state.cholestrolLdl;
    obj.triglycerides = this.state.triglycerides;
    obj.glucose =  this.state.glucose;
    saveBiometricInfo(obj, history, profile);
  };

  render() {
    const {history} = this.props;
    return (
      <React.Fragment>
        <ImageContainer>
          <img
            src="/public/images/Logo/woliba_trans.png"
            alt="woliba-buddy-logo"
            onClick={() => history.push('/')}/>
          <FormTitle>Physician Wellness Screening Form</FormTitle>
        </ImageContainer>
        {this.getFormComponent()}
      </React.Fragment>
    )
  }
}

MultiStepForm.propTypes = {
  history: PropTypes.object,
  getUserBiometricDetails: PropTypes.func,
  saveBiometricInfo: PropTypes.func,
  biometricDetails: PropTypes.object,
  profile: PropTypes.number
};

const mapStateToProps = (state) => ({
  userData: state.register.userData,
  biometricDetails: state.peopleHome.biometricDetails,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSearchPeople: (data, cb) => dispatch(fetchSearchPeople(data, cb)),
  saveUserInformation: (name, value) => dispatch(saveUserInformation(name, value)),
  getUserBiometricDetails: (userId) => dispatch(getUserBiometricDetails(userId)),
  saveBiometricInfo: (userDetail, history, profile) => dispatch(saveBiometricInfo(userDetail, history, profile))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MultiStepForm))
